import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home-page/HomePage';
import Header from './header/Header';
import Footer from './footer/Footer';
import RuleOfThree from '../solvers/rule-of-three/RuleOfThree';
import TipCalculator from '../solvers/tip-calculator/TipCalculator';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import TermsOfUse from '../pages/terms-of-use/TermsOfUse';
import About from '../pages/about/About';
import { Helmet } from 'react-helmet-async';

function App() {
  return (
    <Router>
      <Helmet>
        <title>2Solve | Ferramentas rapidas para resultados imediatos</title>
        <meta name="description" content="O 2Solve oferece uma variedade de ferramentas rápidas para cálculos do dia a dia. Resultados instantâneos sem necessidade de cadastro." />
        <meta
          name="keywords"
          content="ferramentas de cálculo, soluções rápidas, ferramentas online, calculadora online, ferramenta de produtividade, resolver problemas, calcular online, matemática, matemática online, ferramentas matemáticas, cálculo matemático, ferramentas simples, calculadora de interesse, calculadora de porcentagem, calculadora de regra de 3, calculadora de gorjeta, calcular porcentagem, cálculo rápido, ferramentas web, ferramentas gratuitas, produtividade, compartilhamento de resultados"
        />
        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QDQWEM0RZX"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-QDQWEM0RZX');
          `}
        </script>
      </Helmet>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/calculadora-de-regra-de-3" element={<RuleOfThree />} />
          <Route path="/calculadora-de-gorjeta" element={<TipCalculator />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route path="/termos-de-uso" element={<TermsOfUse />} />
          <Route path="/sobre" element={<About />} />
        </Routes>
        <Footer />
      </div>

    </Router>
  );
}

export default App;